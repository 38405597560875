import {AtributoReceitaEntity} from '@catalogo/models';
import {Selector} from '@ngxs/store';
import {Meta} from 'ui-shared';
import {AtributoReceitaState, AtributoReceitaStateModel} from "./atributo-receita-state";

export class AtributoReceitaSelector {
    @Selector([AtributoReceitaState])
    public static atributos({ meta, itens, filtro }: AtributoReceitaStateModel): AtributoReceitaEntity[] {
        return itens;
    }
    @Selector([AtributoReceitaState])
    public static meta(state: AtributoReceitaStateModel): Meta {
        return state?.meta;
    }
    @Selector([AtributoReceitaState])
    public static filtro(state: AtributoReceitaStateModel): string {
        return state?.filtro;
    }

    @Selector([AtributoReceitaState])
    public static selecionado(state: AtributoReceitaStateModel): AtributoReceitaEntity {
        return state?.selecionado;
    }
}
