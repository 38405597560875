import {ApiResult, ListaPaginada, Meta, NotificationService} from 'ui-shared';
import {Action, State, StateContext} from '@ngxs/store';
import {Injectable, NgZone} from '@angular/core';
import {map} from 'rxjs/operators';
import * as actions from './atributo-receita.actions';
import {MatDialog} from '@angular/material/dialog';
import {AtributoReceitaEntity} from '@catalogo/models';
import {AtributoReceitaDataService} from "@catalogo/services/data-services/atributo-receita.data.service";

export class AtributoReceitaStateModel {
    itens?: AtributoReceitaEntity[];
    selecionado?: AtributoReceitaEntity;
    meta?: Meta;
    filtro?: string;
    modalRemover?: string;
}

@State<AtributoReceitaStateModel>({
    name: 'AtributosReceita',
    defaults: {
        itens: [],
        meta: Meta.padrao
    }
})
@Injectable({ providedIn: 'root' })
export class AtributoReceitaState {

    constructor(
        private atributoReceitaDataService: AtributoReceitaDataService,
        public dialog: MatDialog,
        private notification: NotificationService,
        private ngZone: NgZone
    ) { }

    @Action(actions.ObterTodos)
    public getAll({ getState, patchState, dispatch }: StateContext<AtributoReceitaStateModel>, { payload }: actions.ObterTodos) {
        const newMeta = payload.meta || getState().meta;
        const meta = { ...newMeta, ativo: false } as Meta;
        return this.atributoReceitaDataService.getWithQuery(meta)
            .pipe(
                map(({ data }: ApiResult<ListaPaginada<AtributoReceitaEntity>>) =>
                    patchState({
                        itens: data.itens,
                        selecionado: undefined,
                        meta: {
                            ...meta,
                            pagina: data.pagina,
                            total: data.total,
                        }
                    })
                ));
    }

    @Action(actions.AtualizarFiltro)
    public getByFilter({ patchState, getState, dispatch }: StateContext<AtributoReceitaStateModel>, { payload }: actions.AtualizarFiltro) {
        const newMeta = { ...getState().meta, pagina: 1 } as Meta;
        patchState({ filtro: payload, meta: newMeta });
        const filtro = `NomeApresentacao.ToLower().Contains("${payload.toLowerCase().trim()}") OR Codigo.ToLower().Contains("${payload.toLowerCase().trim()}")`;
        const meta = { ...getState().meta, filtro };
        dispatch(new actions.ObterTodos({ meta }));
    }

    @Action(actions.ObterPorId)
    public obterPorId({ getState, patchState }: StateContext<AtributoReceitaStateModel>, { payload }: actions.ObterPorId) {
        return this.atributoReceitaDataService.getById(payload)
            .pipe(map(result => patchState({ selecionado: result.data })));
    }
    
    @Action(actions.ObterPorCodigo)
    public obterPorCodigo({ getState, patchState }: StateContext<AtributoReceitaStateModel>, { payload }: actions.ObterPorId) {
        return this.atributoReceitaDataService.obterPorCodigoPortalUnico(payload)
            .pipe(map(result => patchState({ selecionado: result.data })));
    }
}
